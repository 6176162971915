@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;1,400;1,700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,200;1,300;1,400;1,500&family=Lato:wght@300&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,900;1,200;1,300;1,400;1,600;1,800&family=Playfair+Display:wght@700&family=Poppins:ital,wght@0,300;0,400;1,200;1,300;1,400&family=Roboto:ital,wght@0,300;0,700;0,900;1,700;1,900&family=Rubik+Burned&family=Shadows+Into+Light&display=swap');

.Special_container{
    width: 96%;
    color: white;
    margin: 1vh auto;
  font-family: var(--font-content);
}
.Special_container h2{
    background-color: var(--color-green);
    font-weight: 400;
    font-size: 1.5rem;
    padding: 1vh 0;
    text-align: center;
    margin: 4vh 0;
    font-family: var(--font-headings);
}

.greet{
    color: var(--color-black);
    font-size: 1.1rem;
}
.Special_container h3{
    font-weight: 600;
    color: var(--color-bg2);

}
.Special_container li{
    list-style-type: square;
    /* padding: 1vh 0; */
}
.Special_container .CfSletter{
    text-align: justify;
    font-family: var(--font-content);
    color: black;
    padding: 0.5vh;
    /* border-radius: 2rem 0.5rem; */
    line-height: 1.5rem;
    margin: 1vh 0;
    /* border: 1px solid var(--color-bg2); */

} 

.callSpecialHighlight{
    /* background-color: var(--color-green); */
    background: linear-gradient(to right, var(--color-green), var(--color-green1));

    border: 1px solid var(--color-bg2);
    /* border-radius: 2rem 0.5rem; */
}

.callSpecialHighlight h2{
    /* border-radius: 2rem 0.5rem; */
    
    background: linear-gradient(to right, var(--color-green), var(--color-green1));
    font-weight: 400;
    font-size: 1.5rem;
    padding: 1vh 0;
    text-align: center;
    margin:0 0 4vh 0 ;
    font-family: var(--font-headings);

}

.callSpecialHighlight li{
  list-style-type: circle;    
  margin: 2vh 4vh;
  font-weight: 400;
  font-family: var(--font-content);
  font-size: 1rem;
}


.greet{
    font-weight: 500;
}


/* // button  */
 .submissionLinkhead a{
    color: white;
    /* background-color: var(--color-bg1); */
   /* border-radius: 0.7rem; */
    /* background-color: var(--color-green); */
    /* background: linear-gradient(to left, var(--color-bg), var(--color-green1)); */
    padding: 1vh 2vw;
    font-family: var(--font-content);
    /* opacity: 0.8; */
    color: white;
    font-weight:600;
    /* border: 1px solid var(--color-green1); */

}
.submissionLinkhead a:hover{
    /* background: linear-gradient(to right, var(--color-green), var(--color-green1)); */
}



.paCaSpSession {
    padding: 2vh;
    font-size: 1rem;
}
.paCaSpSession:hover{
    background-color: var(--color-bg2);
}
.paCaSpSession a{
    color: white;
}

@media only screen and (max-width: 730px)  {
    .Submission_con{
        flex-direction: column;
    }
    
}



