.Publication{
    width: 96%;
    margin: 4vh  auto;
    font-family: var(--font-content);
}
.Publication h2{
    background-color: var(--color-green);
    font-family: var(--font-headings);
    font-weight: 400;
    padding: 1vh 0;
    font-size: 1.5rem;
    margin: 4vh 0;
    text-align: center;
    color: white;
}
img{
    height: 100%;
    width: 100%;
}
.puimg{
    height: 40vh;
    width: 30vh;
    transition: all 0.5s ease-in-out;
}
.puimg:hover{
transform: scale(1.1);
}
.publicationMain{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin: 6vh 0;
    gap: 2rem;
}
.puImages{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.imgdata p{
    margin: 2vh 0;
    margin-bottom: 4vh;
    font-size: 1rem;
}
/* publication proceedings  */
.pubProceedings{
  padding: 2rem;
 background: linear-gradient(71deg, #080509, #1a171c, #080509);
 /* border-radius: 2rem 0.5rem; */
 border: 1px solid var(--color-green);
 color: white;
}
.pubProceedings .porange{
    color: var(--color-warning);
    margin: 1vh 0;
}
.pubProceedings h3{
    text-align: center; 
    font-size: 1.3rem;
    padding: 2vh 0;
    border: 1px solid var(--color-bg2);
    margin: 2vh 0;
    font-weight: 500;
    background-color: var(--color-bg1);

}
.pubProceedings p{
    font-size: 1.1rem;
    line-height: 4vh;
}


/* index image  */
.index h2{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
}
.indexImg{ 
    height: 15vh;
    width: 20vh;
    transition: all 0.5s ease-in-out;
}
.indexImg:hover{
transform: scale(1.1);
}
.IndexImages{
    display: grid;
    grid-template-columns: repeat(3,0.5fr);
    gap: 0.5rem;
    margin: 3rem 0;
    justify-items: center;
    /* height: calc(100vh - 250px); */
    align-items: center;
}


/* adding media query  */
@media(max-width:1030px)
{
   

    .publicationMain{
        grid-template-columns: 1fr;
    gap: 1rem;
   

    }

}

@media(max-width:700px)
{


    .puimg{
        height: 30vh;
        width: 20vh;
    }


    .publicationMain{
        grid-template-columns: 1fr;
    gap: 1rem;
   width: fit-content;

    }
    .pubProceedings{
        padding: 1rem;
          background-color: rgb(237,246,250);
      }
    .puImages{
        grid-template-columns: repeat(2,1fr);
    }
    .IndexImages{
        grid-template-columns: repeat(2,1fr);
    gap: 0.5rem;

    }

    .Publication h2{
        font-size: 1.5rem;
        margin-left: 1vh;
    }
    
}